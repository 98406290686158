exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-datenschutz-tsx": () => import("./../../../src/pages/datenschutz.tsx" /* webpackChunkName: "component---src-pages-datenschutz-tsx" */),
  "component---src-pages-impressum-tsx": () => import("./../../../src/pages/impressum.tsx" /* webpackChunkName: "component---src-pages-impressum-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-ueber-uns-tsx": () => import("./../../../src/pages/ueber-uns.tsx" /* webpackChunkName: "component---src-pages-ueber-uns-tsx" */),
  "component---src-templates-workpieces-detail-detail-tsx": () => import("./../../../src/templates/workpieces/detail/detail.tsx" /* webpackChunkName: "component---src-templates-workpieces-detail-detail-tsx" */),
  "component---src-templates-workpieces-overview-overview-tsx": () => import("./../../../src/templates/workpieces/overview/overview.tsx" /* webpackChunkName: "component---src-templates-workpieces-overview-overview-tsx" */)
}

