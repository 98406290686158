/**
 * Navigationsstruktur der Hauptnavigation
 */
export const mainRoutes = [
    {
        text: `Schmuck`,
        to: `/schmuck`
    },
    {
        text: `Trauringe`,
        to: `/trauringe`
    },
    {
        text: `Über uns`,
        to: `/ueber-uns`
    },
    {
        text: `Kontakt`,
        to: `#kontakt`
    }
];
